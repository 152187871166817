
import { defineComponent, inject, PropType, Ref, ref } from 'vue'
import { isHttpsUri } from 'valid-url'

import { required } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import { KoiRepositoryKey } from '@/data/injectables'
import { CreateKoiParams, KoiForm } from '@/data/koi/types'
import { UserLogData } from '@/data/auth/types'

import BlockUI from '@/components/BlockUI.vue'
import FormField from '@/components/FormField.vue'
import NavBar from '@/components/navbar/NavBar.vue'
import UserLog from '@/components/UserLog.vue'
import KoiStatus from '@/views/koi/KoiStatus.vue'

export default defineComponent({
  components: { NavBar, FormField, BlockUI, UserLog, KoiStatus },
  props: {
    koiId: {
      type: Number as PropType<number>,
      default: null,
    },
  },
  setup: () => {
    const koiRepository = inject(KoiRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)

    const form = ref<KoiForm>({
      size: undefined,
      price: undefined,
      status: 'AVAILABLE',
      notes: undefined,
      videoUrl: undefined,
    })

    const v$ = useFormValidation(form)

    return {
      form,
      v$,
      ...toast,
      koiRepository,
      handleError,
    }
  },
  data() {
    return {
      saving: false,
      deleting: false,
      loading: false,
      userLog: {} as UserLogData,
      submitted: false,
    }
  },
  computed: {
    editing(): boolean {
      return !!this.koiId
    },
    title(): string {
      return this.editing ? 'Editar Koi' : 'Criar Koi'
    },
    busy(): boolean {
      return this.loading || this.saving || this.deleting
    },
    price: {
      get(): number {
        return Number(this.form.price || 0)
      },
      set(value: number): void {
        this.form.price = value.toString()
      },
    },
  },
  created() {
    if (this.editing) {
      this.loadFormAndUserLog()
    }
  },
  methods: {
    async handleSave(): Promise<void> {
      this.submitted = true
      if (this.v$.$invalid) return

      this.saving = true
      if (this.editing) {
        await this.updateKoi()
      } else {
        await this.createKoi()
      }
      this.saving = false
    },
    async handleDelete(): Promise<void> {
      this.$confirm.require({
        message: 'Confirma a deleção da koi?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          await this.deleteKoi()
        },
      })
    },
    async updateKoi(): Promise<void> {
      try {
        await this.koiRepository?.updateKoi(this.koiId, this.form)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Koi editada com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async createKoi(): Promise<void> {
      try {
        await this.koiRepository?.createKoi(this.form as CreateKoiParams)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Koi criada com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      }
    },
    async deleteKoi(): Promise<void> {
      try {
        this.deleting = true
        await this.koiRepository?.deleteKoi(this.koiId)
        this.showSuccess({
          title: 'Tudo certo',
          detail: 'Koi deletada com sucesso.',
        })
        this.$router.back()
      } catch (e) {
        this.handleError(e)
      } finally {
        this.deleting = false
      }
    },
    async loadFormAndUserLog(): Promise<void> {
      try {
        this.loading = true
        const { createdBy, modifiedBy, ...form } =
          (await this.koiRepository?.getKoi(this.koiId)) || {}

        this.form = form

        this.userLog = {
          createdBy,
          modifiedBy,
        }
      } catch (e) {
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
  },
})

function useFormValidation(form: Ref<KoiForm>) {
  const validationState = {
    form,
  }

  const validationRules = {
    form: {
      size: {
        required,
      },
      price: {
        required,
      },
      videoUrl: {
        required,
        isValidURL: (url: unknown): boolean => {
          return Boolean(isHttpsUri(String(url)))
        },
      },
    },
  }

  return useVuelidate(validationRules, validationState)
}
