
import { KoiStatus } from '@/data/koi/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: String as PropType<KoiStatus>,
      default: 'AVAILABLE',
    },
  },
  emits: ['update:modelValue'],
  computed: {
    modelValueComputed: {
      get(): KoiStatus {
        return this.modelValue
      },
      set(modelValue: KoiStatus) {
        this.$emit('update:modelValue', modelValue)
      },
    },
  },
})
